.nav {
    width: 100% !important;
    height: 55px;
}

.home {
    height: 300px;
}

.newDoc {
    width: 180px;
    height: 220px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: auto;
    position: relative;
}

.loadingWrapper{
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.2;
    border-radius: 10px;
}

.deleteBtn {
    cursor: pointer;
}

.shareMainWrapper{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    opacity: 0.3;
    animation: fading 200ms 1 both;
}

@keyframes fading {
    0% {
        transform: scale(0.75);
    }

    100% {
        transform: scale(1);
    }
}

.shareWrapper {
    background-color: rgb(223, 222, 222) !important;
    z-index: 1;
    height: 180px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.docText {
    font-size: 12px;
    color: #6b6b6b;
}

.nameInput {
    width: 100px;
}

.editMainWrapper {
    height: 500px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.3;
    animation: fading 200ms 1 both;
}

.editWrapper {
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.subWrapper{
    height: 340px;
}